<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, index) in tokens" :key="index">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>
                {{ getTokenFromString(item.title) }}
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="3">
                <h3>Make Request</h3>
                <v-select
                  label="Type"
                  :items="['Trevor', 'Graph API']"
                  v-model="selectedRequestType"
                >
                </v-select>
                <v-text-field placeholder="URI" v-model="requestURI">
                </v-text-field>
                <v-btn> Execute </v-btn>
              </v-col>
              <v-col cols="9">
                <pre></pre>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Blank",
  mounted() {
    this.$courier.ProductSkeletons.withId("b9156a99-35b5-4c62-0e70-08d9a3a14ec5").PriceRules.getData(1,5,"").then(x => console.log(x))    
  },
  methods: {
    makeRequest() {
      if (this.selectedRequestType == "Trevor") {
      } else {
      }
    },
    getTokenFromString(tokenName) {
      console.log(tokenName);
      if (tokenName.includes("Id")) {
        return this.$store.getters.loggedInUser.idToken;
      } else if (tokenName.includes("Trevor")) {
        return this.$store.getters.getTrevorAccessToken;
      } else if (tokenName.includes("Graph")) {
        return this.$store.getters.getGraphAccessToken;
      }
    }
  },
  data: () => ({
    jsonResult: "",
    requestURI: "",
    selectedRequestType: "Trevor",
    tokens: [
      {
        title: "Id Token",
        token: ""
      },
      {
        title: "Access Token (For Trevor API)",
        token: ""
      },
      {
        title: "Access Token (For Graph API)",
        token: ""
      }
    ]
  })
};
</script>
